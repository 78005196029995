<template>
  <div>
    <!-- Start Header -->
    <div class="ContentHeader">
      <div class="HeaderAll">
        <!-- Slot Breadcrumb -->
        <slot name="titlebreadcrumb"></slot>

        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <!-- Slot Benefit Header-->
      <slot name="benefitheader"></slot>
    </div>

    <!-- End Header -->

    <!-- slot menu -->
    <slot name="menuall"></slot>

    <!-- end slot menu -->
    <div
      class="allcard"
      style="border-top-left-radius: 0px; height: calc(100vh - (282px + 3.7vh))"
    >
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <!-- start content here -->

        <!-- advanced search -->
        <div style="display: flex; justify-content: flex-end">
          <div class="ContentHeaderSearch contentsearchpt">
            <a-input-search
              placeholder="Search Here"
              style="width: 327px"
              @search="onSearch"
              allowClear
              :default-value="
                $route.query.search != undefined ? $route.query.search : ''
              "
            />

            <a-button
              type="advancedsearch"
              ref="buttonadvanced"
              @click="advanced"
              ><a-badge :dot="dotAdvancedSearch"
                >Advanced Search <a-icon type="filter"
              /></a-badge>
            </a-button>
            <div
              class="advancedsearch-dropdown"
              :style="advancedsearch ? 'display: block;' : 'display: none;'"
              @click="closeadvance"
            >
              <div class="_dropdownwraper" :style="{ top: top + 'px' }">
                <div class="button-right-top-pt">
                  <a-button type="link" @click="advanced">
                    <span class="ant-modal-close-x">
                      <a-icon type="close" /></span
                  ></a-button>
                </div>
                <a-form :form="form" @submit="Submit">
                  <a-row :gutter="[15, 15]">
                    <a-col :lg="6">
                      <a-form-item label="Nama Perusahaan">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'nama_perusahaan',
                            {
                              initialValue:
                                $route.query.nama_perusahaan != undefined
                                  ? $route.query.nama_perusahaan
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :lg="6">
                      <a-form-item label="Alamat">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'alamat',
                            {
                              initialValue:
                                $route.query.alamat != undefined
                                  ? $route.query.alamat
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :lg="6">
                      <a-form-item label="No.Polis">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'no_polis',
                            {
                              initialValue:
                                $route.query.no_polis != undefined
                                  ? $route.query.no_polis
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :lg="6">
                      <a-form-item label="Tgl Pembuatan">
                        <a-range-picker
                          autocomplete="off"
                          v-decorator="[
                            'created_at',
                            {
                              initialValue:
                                $route.query.created_at != undefined
                                  ? $route.query.created_at
                                  : null,
                            },
                          ]"
                          style="width: 100%"
                          format="DD MMM YYYY"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :lg="24">
                      <div class="button-right">
                        <a-button type="default" @click="Reset">Reset</a-button>
                        <a-button type="primary" html-type="submit"
                          >Cari</a-button
                        >
                      </div>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </div>
          </div>
        </div>
        <!-- end advanced search -->
        <a-table
          :rowKey="(record) => record.id"
          :columns="columns"
          :dataSource="data"
          :pagination="false"
          :scroll="{ x: widthtable }"
          :loading="loading"
          @change="handleTableChange"
        >
          <template slot="total_peserta" slot-scope="text">
            <div class="iconpeserta">
              <img :src="require('@/assets/img/icon/peserta.svg')" /> {{ text }}
            </div>
          </template>
          <template slot="tgl_pembuatan" slot-scope="text">
            <div class="iconcalendar">
              <img :src="require('@/assets/img/icon/calendar.svg')" />
              {{ text }}
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <a-button
              type="download"
              @click="view(record.id, record.nama_perusahaan)"
              >View <img :src="require('@/assets/img/icon/eye.svg')"
            /></a-button>

            <a-button type="download" @click="importdatapeserta(record.id)"
              >Import <img :src="require('@/assets/img/icon/download.svg')"
            /></a-button>
          </template>

          <template slot="footer">
            {{ null }}
          </template>
        </a-table>
        <!-- end content -->

        <!-- pagination -->
        <div class="paginationcustom" style="margin-top: 0px !important">
          <a-pagination
            showSizeChanger
            @showSizeChange="onShowSizeChange"
            :pageSizeOptions="pageSizeOption"
            :pageSize.sync="pageSize"
            :total="total"
            v-model="page"
            :showTotal="
              (total, range) =>
                ` ${range[0]}-${range[1]}  of ${
                  pagination.total == undefined ? '0' : pagination.total
                } `
            "
            @change="handlePageChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '1000000'">
                {{ props.value }} / page
              </span>
              <span v-if="props.value == '1000000'">All</span>
            </template>
          </a-pagination>
        </div>
        <!-- end pagination -->
      </simplebar>
    </div>
    <ModalImportDataPeserta ref="importData" />
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import ModalImportDataPeserta from "@/components/Modal/importDataPesertaPT.vue";
const columns = [
  {
    title: "Nama Perusahaan",
    dataIndex: "nama_perusahaan",
    sorter: true,
    width: 300,
  },
  {
    title: "Alamat",
    dataIndex: "alamat",
    sorter: true,
    width: 400,
  },
  {
    title: "No.Polis",
    dataIndex: "no_polis",
    sorter: true,
    width: 150,
  },
  {
    title: "Total Peserta",
    dataIndex: "total_peserta",
    sorter: true,
    align: "center",
    width: 150,
    scopedSlots: { customRender: "total_peserta" },
  },
  // {
  //   title: "Tgl Pembuatan",
  //   dataIndex: "tanggal_pembuatan",
  //   sorter: true,

  //   width: 200,
  //   scopedSlots: { customRender: "tgl_pembuatan" },
  // },

  {
    title: "Action",
    scopedSlots: { customRender: "action" },
    width: 300,
    align: "center",
    fixed: "right",
  },
];
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormAdvancedSearcg" });
  },
  components: {
    ModalImportDataPeserta,
    simplebar,
  },
  data() {
    return {
      pageSizeOption: ["15", "30", "60", "1000000"],
      pagination: {},
      total: 20,
      page: 1,
      current: 1,
      pageSize: 15,
      loading: false,
      data: [],
      columns,
      dotAdvancedSearch:
        Object.keys(this.$route.query).length >= 15 &&
        this.$route.query.search == undefined
          ? true
          : Object.keys(this.$route.query).length >= 15 &&
            this.$route.query.search != ""
          ? true
          : false,
      search:
        this.$route.query.search != undefined ? this.$route.query.search : "",
      advancedsearch: false,
      paramssearch:
        this.$route.query.page != undefined &&
        this.$route.query.pageSizes != undefined
          ? Object.keys(this.$route.query).reduce((obj, key) => {
              if (key !== "page" && key !== "pageSizes") {
                obj[key] = this.$route.query[key];
              }

              return obj;
            }, {})
          : {},
      top: 0,
      heighttable: 0,
      widthtable: 0,
      breadcrumb: [
        {
          name: "Daftar Group",
          nameroute: "ListDaftarGroup",
          param: {},
        },
        {
          name: "Daftar PT",
          nameroute: "",
          param: {},
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    const search = this.search != "" ? { search: this.search } : {};
    const params = this.paramssearch != undefined ? this.paramssearch : {};

    this.fetch({
      page: this.page,
      pageSizes: this.pageSize,
      id: this.$route.params.id,
      ...search,
      ...params,
    });
  },
  // created() {
  //   let self = this;

  //   window.addEventListener("click", function (e) {
  //     // close dropdown when clicked outside
  //     if (!self.$el.contains(e.target)) {
  //       self.advancedsearch = false;
  //     }
  //   });
  // },
  methods: {
    handleResize() {
      this.heighttable = screen.height - 785;
      this.widthtable = screen.width - 200;
    },
    importdatapeserta(id) {
      this.$refs.importData.Show(id);
    },
    view(e, p) {
      //console.log(e);
      this.$router.push({
        name: "ListDataPeserta",
        params: { id: this.$route.params.id },
        query: { id_perusahaan: e, nama_perusahaan: p },
      });
    },
    Reset() {
      this.paramssearch = {};
      this.dotAdvancedSearch = false;
      this.form.resetFields();
      this.advancedsearch = false;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        id: this.$route.params.id,
      });

      this.handlePageChange(this.page);
    },
    Submit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.advancedsearch = false;
          this.fetch({
            page: 1,
            pageSizes: this.pageSize,
            id: this.$route.params.id,
            ...values,
          });

          this.advancedsearch = false;
          this.paramssearch = values;
          this.dotAdvancedSearch = true;
        }

        const search = this.search != "" ? { search: this.search } : {};

        this.$router.push({
          query: {
            page: 1,
            pageSizes: this.pageSize,
            ...search,
            ...this.paramssearch,
          },
        });
      });
    },
    onShowSizeChange(current, pageSize) {
      const params = this.paramssearch;
      this.page = current;
      this.pageSize = pageSize;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        id: this.$route.params.id,
        search: this.search,
        ...params,
      });
      this.handlePageChange(current);
    },
    handlePageChange(page) {
      //checklist reset
      const search = this.search != "" ? { search: this.search } : {};
      const params = this.paramssearch != undefined ? this.paramssearch : {};
      const pager = { ...this.pagination };
      pager.current = page;

      if (params.search != undefined) {
        delete params.search;
      }

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        id: this.$route.params.id,
        ...search,
        ...params,
      });

      this.$router.push({
        query: {
          page: pager.current,
          pageSizes: pager.pageSize,
          id: this.$route.params.id,
          ...search,
          ...params,
        },
      });
    },
    handleTableChange(page, filter, sorter) {
      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;

      if (params.search != undefined) {
        delete params.search;
      }

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        id: this.$route.params.id,
        sortField: sorter.field,
        sortOrder: sorter.order,
        search: this.search,
        ...params,
      });

      this.$router.push({
        page: pager.current,
        pageSizes: pager.pageSize,
        id: this.$route.params.id,
        sortField: sorter.field,
        sortOrder: sorter.order,
        search: this.search,
        ...params,
      });
    },
    advanced(e) {
      this.advancedsearch = !this.advancedsearch;
      this.top = this.$refs.buttonadvanced.$el.offsetTop + 305;

      //console.log(this.$refs.buttonadvanced);
    },

    closeadvance(e) {
      if (e.target._prevClass == "advancedsearch-dropdown") {
        this.advancedsearch = false;
      }
    },
    fetch(params = {}) {
      //console.log(params);
      this.loading = true;
      this.$store
        .dispatch("GetDaftarPerusahaan", {
          ...params,
        })
        .then((response) => {
          this.loading = false;
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          pagination.pageSize = response.data.pageSize;
          pagination.page = response.data.page;

          this.total = pagination.total;
          this.data = response.data.data;
          this.pagination = pagination;
          this.model = [];
        });
    },
    onSearch(value) {
      this.search = value;

      const search = this.search != "" ? { search: this.search } : {};
      this.fetch({
        page: 1,
        pageSizes: this.pageSize,
        id: this.$route.params.id,
        ...search,
      });
      this.$router.push({
        query: {
          page: 1,
          pageSizes: this.pageSize,
          ...search,
        },
      });
    },
  },
};
</script>
